// Light mode colors
export const lmColors = {
  primary: (alpha: number = 1) => `hsla(190, 72%, 48%, ${alpha})`,
  secondary: (alpha: number = 1) => `hsla(206, 88%, 55%,${alpha})`,
  background: (alpha: number = 1) => `hsla(240, 100%, 98%,${alpha})`,
  surface: (alpha: number = 1) => `hsla(240, 100%, 98%,${alpha})`,
  error: (alpha: number = 1) => `hsla(349, 78%, 62%,${alpha})`,
  onPrimary: (alpha: number = 1) => `hsla(51, 100%, 1%,${alpha})`,
  onSecondary: (alpha: number = 1) => `hsla(51, 100%, 1%,${alpha})`,
  onBackground: (alpha: number = 1) => `hsla(51, 100%, 1%,${alpha})`,
  onSurface: (alpha: number = 1) => `hsla(51, 100%, 1%,${alpha})`,
  onError: (alpha: number = 1) => `hsla(240, 100%, 98%,${alpha})`,

  // Dark mode colors
  // export const dmColors = {
  //   primary: (alpha: number=1) => `hsla(,,,${alpha})`
};

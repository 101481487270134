export const landingData = {
  headline: "Integrated Compliance",
  preHeadline: "Coming Soon",
  subHeadline:
    "Keep your company and employees safe and compliant with our upcoming comprehensive corporate compliance training course. We go beyond safety standards and regulations to strategies for integrating compliance principles to your company culture.",
  newsletterSub: {
    miniLabel: "Sign up to get notified",
    inputPlaceholder: "Enter your email",
    buttonLabel: "Notify me",
  },
  privacyPolicy: {
    preamble: "We care about the protection of your data. Read our ",
    linkText: "Privacy Policy.",
    link: "#",
  },
};

export const courseData = {
  header: "Integrative Compliance Course Outline",
  curriculum: [
    {
      id: "12312412f3",
      number: 1,
      title: "Foundations of Corporate Compliance",
      sections: [
        {
          id: "235235t2g4",
          number: "a",
          title: "What is Compliance?",
        },
        {
          id: "g24235th3g",
          number: "b",
          title: "Building Your Corporate Values",
        },
        {
          id: "h64hj4",
          number: "c",
          title: "Regulatory Mapping",
        },
        {
          id: "j464j6",
          number: "d",
          title: "Compliance Calendar",
        },
      ],
    },
    {
      id: "g35gh35h",
      number: 2,
      title: "Policy and Program Development",
      sections: [
        {
          id: "0546540wdf",
          number: "a",
          title: "Tone at the Top",
        },
        {
          id: "49491dewf",
          number: "b",
          title: "Organizational Charting",
        },
        {
          id: "4798g49wr",
          number: "c",
          title: "Policy Development",
        },
        {
          id: "984w92gwe",
          number: "d",
          title: "Guidelines and Operating Procedures",
        },
      ],
    },
    {
      id: "wgrewjyrj46",
      number: 3,
      title: "Training",
      sections: [
        {
          id: "teth959",
          number: "a",
          title: "Content Development",
        },
        {
          id: "ewret256156",
          number: "b",
          title: "Training Calendar",
        },
      ],
    },
    {
      id: "gwhetryjhdsgdh",
      number: 4,
      title: "Audits and Investigations",
      sections: [
        {
          id: "wd312et4r",
          number: "a",
          title: "What do Audits Entail?",
        },
      ],
    },
    {
      id: "wgrh3u6j47i5i",
      number: 5,
      title: "Records and Reporting",
      sections: [
        {
          id: "6a5sda4sd64as56d4a5s",
          number: "a",
          title: "Integrated Recordkeeping",
        },
        {
          id: "g2423gas54g6adf",
          number: "b",
          title: "Reporting Avenues",
        },
      ],
    },
  ],
};

export const aboutData = {
  header: "What ladder is",
  brief:
    "Ladder supports companies to develop smart, value-driven, and on-brand compliance programs and training solutions that integrate into each role from top-down. We create compliance programs which feel part and parcel of a company’s identity and mission, not a cumbersome add-on.",
  sections: [
    {
      id: "12413r41t2gf42",
      header:
        "Is your Corporate Compliance Program boosting company culture and team morale? Here’s what we know doesn’t work.",
      description:
        "Seemingly endless hours of mundane and generic training, voluminous policies and guidelines, the concern of missing a step and facing penalties—it’s all no longer working. It’s also a mammoth responsibility for compliance officers to keep up with training, recordkeeping, policy updates, audits and other compliance related responsibilities effectively.",
    },
    {
      id: "f432hy35y53y3525",
      header: "The Ladder Solution",
      description:
        "We make corporate compliance an integrated part of your internal and external corporate brand and a seamless day-to-day part of every role. Your value-driven compliance program can be the center of pride and enthusiasm for every team member, and what distinguishes you on the market. It’s the how and why in everything you do. We help you leverage a unique brand through a modern, smart and effective corporate compliance program.",
    },
    {
      id: "25g36grtge6e64ye46y4",
      header: "Our training programs",
      list: [
        {
          id: "2g5462h376hg",
          header: "Compliance Officers & Business Leaders",
          description:
            "We offer a 5-part Integrated Compliance training program that introduces you to the foundations of Corporate Compliance, and the modern approaches that will make your program effective, value-driven and on-brand.",
        },
        {
          id: "42t3q2g3756yh",
          header: "Employee Training",
          description:
            "Need customized training? We can help you develop on-brand content to support your training needs.",
        },
      ],
    },
  ],
};

import styled from "styled-components";
import { Courses } from "../features/courses/courses";
import { About } from "../features/home/about";
import { Landing } from "../features/home/landing";
import { lmColors } from "../ui/colors";

const Home = () => {
  return (
    <Container>
      <Landing />
      <About />
      <Courses />
    </Container>
  );
};

const Container = styled.main`
  display: grid;
  gap: 5em;
  padding-block-start: 4.5em;
  max-width: 100vw;
  background-color: ${lmColors.background()};
`;

export default Home;

import styled from "styled-components";
import { lmColors } from "../../ui/colors";
import { device } from "../../ui/media-queries";
import { courseData } from "./courseData";
import courseImg from "../../assets/office-smiles.png";

export const Courses = () => {
  const { header, curriculum } = courseData;
  return (
    <Container id="courses">
      <Header>{header}</Header>
      <CourseImage src={courseImg} alt="office" />
      <Content>
        {curriculum.map((course) => (
          <ListItem key={course.id}>
            <h3>
              {course.number} {course.title}
            </h3>
            <List>
              {course.sections.map((section) => (
                <ListItem key={section.id}>
                  {section.number} {section.title}
                </ListItem>
              ))}
            </List>
          </ListItem>
        ))}
      </Content>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  gap: 1em;
  align-content: center;
  min-height: 70vh;
  max-width: 100%;
  grid-template-areas: "header" "image" "curriculum";
  padding-inline: clamp(1em, 5vw, 6vw);

  @media ${device.tablet} {
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "header image" "curriculum image";
  }
`;

const Header = styled.div`
  grid-area: header;
  align-self: flex-end;
  font-weight: 500;
  font-size: 1.5rem;
  color: ${lmColors.secondary()};
`;

const CourseImage = styled.img`
  grid-area: image;
  max-width: 100%;
  object-fit: contain;

  @media ${device.laptop} {
    border-radius: 1em;
    max-width: min(40vw, 100%);
    border: 0.5px solid ${lmColors.onSurface(0.2)};
    filter: drop-shadow(0.3em 0.3em 0.1em ${lmColors.onSurface(0.2)});
  }
`;

const Content = styled.div`
  display: grid;
  gap: 1em;
  grid-area: curriculum;
`;

const List = styled.ul`
  line-height: 150%;
`;
const ListItem = styled.li`
  list-style: none;
`;

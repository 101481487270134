import styled from "styled-components";
import { lmColors } from "../../ui/colors";
import { aboutData } from "./aboutData";

export const About = () => {
  const { header, brief, sections } = aboutData;
  return (
    <Container id="about">
      <MainHeader>{header}</MainHeader>
      <p>{brief}</p>
      {sections.map(({ id, header, description, list }) => (
        <Section key={id}>
          <h2>{header}</h2>
          {description ? (
            <p>{description}</p>
          ) : list ? (
            list.map(({ id, header, description }) => (
              <p key={id}>
                {header} - {description}
              </p>
            ))
          ) : (
            ""
          )}
        </Section>
      ))}
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  gap: 1em;
  justify-content: center;
  min-height: 70vh;
  max-width: 100%;
  padding-inline: clamp(1em, 5vw, 6vw);
  line-height: 150%;
  text-align: center;
`;

const MainHeader = styled.h1`
  color: ${lmColors.secondary()};
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
`;

const Section = styled.div`
  display: grid;
  gap: 1em;
`;

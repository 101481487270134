import { useState } from "react";
import styled from "styled-components";
import { landingData } from "./landingData";
import heroImage from "../../assets/smiling-woman.png";
import { lmColors } from "../../ui/colors";
import { device } from "../../ui/media-queries";

export const Landing = () => {
  const [newsletterEmail, setNewsletterEmail] = useState("");
  const { headline, preHeadline, subHeadline, newsletterSub, privacyPolicy } =
    landingData;
  return (
    <Container id="landing">
      <TextContainer>
        <PreHeadline>{preHeadline}</PreHeadline>
        <Headline>{headline}</Headline>
        <p>{subHeadline}</p>
        <Newsletter>
          <SmallText>{newsletterSub.miniLabel}</SmallText>
          <InlineForm>
            <Input
              placeholder={newsletterSub.inputPlaceholder}
              value={newsletterEmail}
              onChange={(e) => setNewsletterEmail(e.target.value)}
            />
            <Button type="submit">{newsletterSub.buttonLabel}</Button>
          </InlineForm>
          <SmallText>
            {privacyPolicy.preamble}
            <a href="#about">{privacyPolicy.linkText}</a>
          </SmallText>
        </Newsletter>
      </TextContainer>
      <HeroImage src={heroImage} alt="Smiling Woman" />
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  grid-template-areas: "heroImage" "headline";
  align-content: center;
  min-height: 70vh;
  max-width: 100%;

  @media ${device.laptop} {
    grid-template-areas: "headline heroImage";
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
`;

const TextContainer = styled.div`
  display: grid;
  grid-area: headline;
  align-content: center;
  gap: 1em;
  padding-inline: clamp(1em, 5vw, 6vw);
  line-height: 200%;
`;

const Headline = styled.h1`
  color: ${lmColors.secondary()};
  font-size: clamp(1.4em, 1.7em, 2em);
  font-weight: 600;
  text-transform: uppercase;
  margin-block-end: min(3em, 5vh);
`;

const PreHeadline = styled.span`
  text-transform: uppercase;
`;

const HeroImage = styled.img`
  justify-self: center;
  align-self: center;
  grid-area: heroImage;
  max-width: 100%;
  object-fit: contain;

  @media ${device.laptop} {
    border-radius: 1em;
    max-width: min(40vw, 100%);
    border: 0.5px solid ${lmColors.onSurface(0.2)};
    filter: drop-shadow(0.3em 0.3em 0.1em ${lmColors.onSurface(0.2)});
  }
`;

const Newsletter = styled.div`
  display: grid;
  justify-content: stretch;
  align-content: center;
`;

const InlineForm = styled.form`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 1em;
`;

const Input = styled.input`
  padding: 0.7em;
  outline: none;
  border-radius: 0.4em;
  font-size: 1rem;
`;

const SmallText = styled.p`
  font-size: 0.8em;
  a {
    color: inherit;
  }
`;

const Button = styled.button`
  padding: 0.8em;
  color: white;
  background-color: ${lmColors.onBackground(0.5)};
  border-radius: 0.6em;
  font-size: 1rem;
`;

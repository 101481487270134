import { createSlice } from "@reduxjs/toolkit";
import { Course as CourseType } from "../../types/Course";

export interface CoursesState {
  list: CourseType[];
  status: "idle" | "loading" | "failed";
}

const initialState: CoursesState = {
  list: [],
  status: "idle",
};

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
});

export default coursesSlice.reducer;

import { useState } from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import logo from "../../assets/logo.svg";
import { lmColors } from "../../ui/colors";
import { device } from "../../ui/media-queries";

export const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const toggleNav = () => setToggle(!toggle);

  return (
    <Container>
      <LogoBar>
        <Brand href="#landing">
          <Logo src={logo} alt="Logo" />
        </Brand>
        <MenuToggle>
          {toggle ? (
            <FontAwesomeIcon icon={faTimes} onClick={toggleNav} />
          ) : (
            <FontAwesomeIcon icon={faBars} onClick={toggleNav} />
          )}
        </MenuToggle>
      </LogoBar>
      <NavList toggle={toggle}>
        <NavItem>
          <NavLink href="#about" onClick={toggleNav}>
            About
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#courses" onClick={toggleNav}>
            Courses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#contact" onClick={toggleNav}>
            Contact
          </NavLink>
        </NavItem>
      </NavList>
    </Container>
  );
};

const Container = styled.nav`
  display: grid;
  align-content: flex-start;
  text-transform: uppercase;
  position: fixed;
  inset: 0 0 auto 0;
  z-index: 1000;

  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    padding-inline: clamp(1em, 5vw, 6vw);
    background-color: ${lmColors.background()};
    filter: drop-shadow(0.1em 0.1em 0.3em ${lmColors.onSurface(0.4)});
  }
`;

const LogoBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  background-color: ${lmColors.surface()};
  box-shadow: 0.2em 0.8em 1em ${lmColors.onSurface(0.2)};

  @media ${device.laptop} {
    background-color: unset;
    box-shadow: none;
  }
`;

const Brand = styled.a`
  padding-block: 0.3em;
`;

const Logo = styled.img`
  height: clamp(2em, 3em, 4em);
`;

const MenuToggle = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 0.1em;
  width: clamp(1.2em, 1.7em, 3.2em);
  font-size: clamp(1em, 1.5em, 3em);
  background-color: ${lmColors.onSurface(0.2)};
  aspect-ratio: 1;

  @media ${device.laptop} {
    display: none;
  }
`;

const NavList = styled.ul<{ toggle?: boolean }>`
  display: ${(props) => (props.toggle ? "grid" : "none")};
  gap: 1em;
  background-color: ${lmColors.surface(0.8)};
  backdrop-filter: blur(1rem);
  padding-block: 1em;

  @media ${device.laptop} {
    display: flex;
    justify-content: end;
    background-color: unset;
  }
`;

const NavItem = styled.li`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

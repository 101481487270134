import { createSlice } from "@reduxjs/toolkit";
import { User as UserType } from "../../types/User";

export interface UsersState {
  list?: UserType[];
  currentUser?: UserType;
}

const initialState: UsersState = {
  list: [],
  currentUser: undefined,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
});

export default usersSlice.reducer;

import { Route, Routes } from "react-router-dom";
import { Navbar } from "../features/navigation/navbar";
import Home from "./Home";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./pages/App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faBars,
	faCaretDown,
	fas,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter } from "react-router-dom";

library.add(fas, faBars, faTimes, faCaretDown);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
